<template>
  <div class="block__flex block__align-center block__column" style="position: relative">
    <div style="width: 100%" :class="`input-i5 ${setFocus()}`">
      <label :for="name" @mousedown="(e) => e.preventDefault()"
        >{{ label }}
        <span v-if="required">*</span>
        <span v-if="subLabel && !error" class="input-i5__sublabel">
          {{ subLabel }}
        </span>
        <span v-if="error" class="input-i5__error">
          {{ error }}
        </span>
      </label>
      <div style="position: relative">
        <input
          :tabindex="ti"
          :class="`${error ? 'input-i5_error' : ''}`"
          @focus="handleFocus"
          @blur="handleFocusOut"
          @click="handleClick"
          :value="value"
          :id="name"
          :name="name"
          autocomplete="off"
          readonly
          :disabled="disabled === true"
          :style="`${clearable ? 'padding-right: 20px;' : ''}`"
          @input="handleChange"
        />
        <ClearImage v-if="clearable && value" class="input-i5__clear" @click="clear" />
      </div>
    </div>
    <div
      @mousedown="(e) => e.preventDefault()"
      :id="`${name}_dropdown`"
      :style="{
        width: `${elWidth}px`,
        maxHeight: `${dropDownMaxHeight}px`,
        marginTop: `${elHeight + 2}px`,
        position: setPosition(),
        left: 0,
      }"
      :class="`search__calendar-container ${setOpen()} ${setDirection()}`"
    >
      <div class="block" style="overflow-y: hidden" @mousedown="(e) => e.preventDefault()">
        <Calendar
          @mousedown="(e) => e.preventDefault()"
          :show-disabled="false"
          :default-date="value"
          @setDate="setDate($event)"
        />
        <!--<div
          class="search__calendar_days"
          :style="`max-height: ${dropDownMaxHeight}px`">
          <div
            v-for="(day, index) in days"
            @click="save(index, 'day')"
            v-bind:key="`${day}_${name}_day`"
            :class="`search__calendar_item ${index === dayIndex ? 'search__calendar_active' : ''}`">
            {{day}}
          </div>
        </div>
        <div
          class="search__calendar_months"
          :style="`max-height: ${dropDownMaxHeight}px`">
          <div
            v-for="(month, index) in months"
            @click="save(index, 'month')"
            :class="`search__calendar_item ${
              index === monthIndex ? 'search__calendar_active' : ''}`"
            v-bind:key="`${month}_${name}_month`">
            {{month}}
          </div>
        </div>
        <div
          class="search__calendar_years"
          :style="`max-height: ${dropDownMaxHeight}px`">
          <div
            v-for="(year, index) in years"
            @click="save(index, 'year')"
            :class="`search__calendar_item ${index === yearIndex ? 'search__calendar_active' : ''}`"
            v-bind:key="`${year}_${name}_year`">
            {{year}}
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import ClearImage from "@/assets/images/form/clear.svg";
import Calendar from "@/components/calendar/Calendar.vue";
import moment from "moment";

export default {
  name: "Search",
  data() {
    return {
      focus: false,
      open: false,
      elWidth: null,
      elHeight: 70,
      dropDownMaxHeight: 100,
      dropDownDirection: "bottom", // top
      dropdownTransform: 0,
      value: "",
      dropdownValues: [],
      days: [],
      months: [],
      years: [],
      calendar: null,
      localeData: null,
      dayIndex: null,
      monthIndex: null,
      yearIndex: null,
      date: null,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [Object, String],
      default: null,
    },
    name: {
      type: String,
      default: "search",
    },
    defaultValue: {
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    subLabel: {
      type: String,
    },
    placement: {
      type: String,
      validator: function (value) {
        return ["bottomLeft", "bottom", "bottomRight"].indexOf(value) !== -1;
      },
    },
    positionType: {
      type: String,
      default: "scrollable", // fixed
    },
    active: [Number, String, Object],
    required: {
      type: Boolean,
      default: false,
    },
    ti: {
      type: Number,
      default: 0,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dropdownProps: {
      type: Object, // label, value, img, sublabel
    },
  },
  watch: {
    active: function () {
      this.open = false;
    },
    defaultValue: function (val) {
      if (Date.parse(val)) {
        this.value = moment(val).format("DD.MM.YYYY");
      } else {
        this.value = "";
      }
      this.setActiveData();
    },
    values: function (val) {
      val.forEach((item) => {
        const result = {};
        if (this.dropdownProps.label && item[this.dropdownProps.label]) {
          result.label = item[this.dropdownProps.label];
        }
        if (this.dropdownProps.value && item[this.dropdownProps.value]) {
          result.value = item[this.dropdownProps.value];
        }
        if (this.dropdownProps.img && item[this.dropdownProps.img]) {
          result.img = item[this.dropdownProps.img];
        }
        if (this.dropdownProps.sublabel && item[this.dropdownProps.sublabel]) {
          result.sublabel = item[this.dropdownProps.sublabel];
        }
        this.dropdownValues.push(result);
      });
    },
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      if (!this.disabled) {
        this.open = true;
      }
    },
    handleFocus() {
      this.open = true;
    },
    handleFocusOut() {
      this.open = false;
    },
    setFocus() {
      if (this.focus) {
        return "search__focus";
      }
      return "";
    },
    setOpen() {
      let result = "";
      if (this.open) {
        result = "search__open";
        this.$nextTick(() => {
          const dropdown = document.getElementById(`${this.name}_dropdown`);
          const dropdownWidth = dropdown.offsetWidth;
          const x = dropdown.getBoundingClientRect().x;
          const width = window.innerWidth;
          if (width / 2 < x && x + dropdownWidth > width) {
            this.dropdownTransform = `-${Math.round(x + dropdownWidth - width + 8)}px`;
            dropdown.style.transform = `translate(${this.dropdownTransform}, 0)`;
          } else if (this.placement === "bottomLeft") {
            this.dropdownTransform = this.$el.getBoundingClientRect().x - x;
            dropdown.style.transform = `translate(${this.dropdownTransform}, 0)`;
          }
          this.dropdownToggle(dropdown);
        });
      }
      return result;
    },
    dropdownToggle() {
      // const y = this.$el.getBoundingClientRect().y;
      this.dropDownDirection = "bottom";
      this.dropDownMaxHeight = 300;
      // console.log('bottom', window.innerHeight - y - 30);
    },
    setDateFormat() {
      let result = "";
      if (this.value && Date.parse(this.value)) {
        const splitDate = this.value.split(".");
        result = new Date(splitDate[2], splitDate[1], splitDate[0]);
      }
      return result;
    },
    clear() {
      this.value = "";
      this.$emit("clear");
    },
    setDate(date) {
      this.value = date;
      this.open = false;
      this.$emit("change", date);
    },
    setDirection() {
      return `search_${this.dropDownDirection}`;
    },
    setPosition() {
      let result = "";
      if (this.positionType === "scrollable") {
        result = "absolute !important";
      } else if (this.positionType === "fixed") {
        result = "fixed !important";
      }
      return result;
    },
    handleChange(evt) {
      this.value = evt.target.value;
    },
    setElement(val) {
      this.value = val.label;
      this.$emit("change", val);
      this.open = false;
      document.getElementById(`${this.name}`).blur();
    },
    createDays() {
      this.days = [...Array(32).keys()];
      this.days = this.days.pop();
    },
    createMonths() {
      this.months = this.calendar.months();
    },
    createYears() {
      const result = [];
      result.push(new Date().getFullYear());
      this.years = result;
    },
    setActiveData() {
      const splitDate = this.value.split(".");
      this.dayIndex = +splitDate[0] - 1;
      this.monthIndex = +splitDate[1] - 1;
      this.yearIndex = this.years.findIndex((year) => year === +splitDate[2]);
    },
    save(index, type) {
      let result = "";
      if (type === "day") {
        this.dayIndex = index;
      } else if (type === "month") {
        this.monthIndex = index;
      } else if (type === "year") {
        this.yearIndex = index;
      }
      result = moment(
        new Date(+this.years[this.yearIndex], this.monthIndex, this.dayIndex + 1),
      ).format("DD.MM.YYYY");
      this.value = result;
    },
  },
  mounted() {
    this.calendar = moment;
    this.calendar.locale(this.$store.state.index.language);
    this.localeData = this.calendar.localeData();
    this.createDays();
    this.createMonths();
    this.createYears();
    this.elWidth = 252;
    this.value = moment(this.defaultValue).format("DD.MM.YYYY");
    this.setActiveData();
  },
  components: {
    ClearImage,
    Calendar,
  },
};
</script>
