<template>
  <Modal :title="text.create_offer[language]" :show="showModal" @close="closeModal">
    <template slot="body">
      <div class="order__kp_container">
        <div class="order__kp_left">
          <div class="order__kp_body">
            <div class="component__margin-bottom">
              <div class="order__label">
                {{ text.outer_number[language] }}
              </div>
              <Input
                @change="resetTimer($event, 'kp_outer_number')"
                :label="text.number[language]"
                :default-value="kpData.kp_outer_number || ''"
              />
              <CalendarInput
                @change="resetTimer($event, 'kp_outer_date')"
                :label="text.date[language]"
                :default-value="kpData.kp_outer_date || ''"
              />
            </div>
            <div class="component__margin-bottom">
              <div class="order__label">
                {{ text.whom[language] }}
              </div>
              <Textarea
                @change="resetTimer($event, 'kp_to_whom')"
                :default-value="kpData.kp_to_whom || ''"
                class="component__margin-top"
              />
            </div>
            <div class="component__margin-bottom">
              <div class="order__label">
                {{ text.text_before_estimate[language] }}
              </div>
              <Textarea
                @change="resetTimer($event, 'kp_text_before_price')"
                :default-value="kpData.kp_text_before_price || ''"
                class="component__margin-top"
              />
            </div>
            <!--<div class="component__margin-bottom">
              <div class="order__label component__margin-bottom">
                {{text.estimate[language]}}
              </div>
              <div class="block component__margin-bottom">
                <a-radio
                  :checked="estimateType === 1"
                  @change="setEstimateType(1)">
                </a-radio>
                <div>{{text.show_detailed_cost[language]}}</div>
              </div>
              <div class="block">
                <a-radio
                  :checked="estimateType === 2"
                  @change="setEstimateType(2)">
                </a-radio>
                <div>{{text.show_total_cost[language]}}</div>
              </div>
            </div> -->
            <div class="component__margin-bottom">
              <div class="order__label">
                {{ text.text_after_estimate[language] }}
              </div>
              <Textarea
                @change="resetTimer($event, 'kp_text_after_price')"
                :default-value="kpData.kp_text_after_price || ''"
                class="component__margin-top"
              />
            </div>
            <div class="component__margin-bottom">
              <div class="order__label">
                {{ text.signatures[language] }}
              </div>
              <Textarea
                @change="resetTimer($event, 'kp_text_signature')"
                :default-value="kpData.kp_text_signature || ''"
                class="component__margin-top"
              />
            </div>
          </div>
          <div class="order__kp_button">
            <a
              :href="`https://api.i5.solutions/getCommercialOfferPDF?t=${token}&uuid=${this.$route.params.id}`"
              download
            >
              <Button :text="text.save_pdf[language]" class="component__margin-bottom" block />
            </a>
            <Button
              :disabled="!kpData.total_price"
              :text="text.send_customer[language]"
              :type="'attention'"
              block
              @click="sendToCustomer"
            />
          </div>
        </div>
        <div class="order__kp_right" v-if="showPdf">
          <Pdf
            class="order__kp_pdf"
            v-for="i in pageCount"
            @error="onError"
            :key="i"
            :src="pdfSrc"
            :page="i"
          ></Pdf>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/i5Modal/I5Modal.vue";
import text from "@/assets/text/order.json";
import Button from "@/components/button/Button.vue";
import Input from "@/components/input/Input.vue";
import CalendarInput from "@/components/inputCalendar/InputCalendar.vue";
import Textarea from "@/components/textarea/Textarea.vue";
import { query } from "@/utilities/axios";
import Pdf from "vue-pdf";

export default {
  name: "KpModal",
  data() {
    return {
      text,
      showModal: false,
      pdfSrc: null,
      pageCount: null,
      currentPage: 0,
      estimateType: 1,
      kpData: {},
      timer: null,
      sendData: {},
      showPdf: true,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: function (val) {
      this.showModal = val;
      this.getPdf();
      this.getKPInfo();
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.$emit("close");
    },
    sendToCustomer() {
      query(
        "post",
        "estimateOrderByManuf",
        {
          uuid: this.$route.params.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.closeModal();
      });
    },
    getPdf() {
      this.pdfSrc = Pdf.createLoadingTask(
        `https://api.i5.solutions/getCommercialOfferPDF?t=${localStorage.getItem("token")}&uuid=${
          this.$route.params.id
        }`,
      );
      this.pdfSrc.promise.then((pdf) => {
        this.pageCount = pdf.numPages;
      });
    },
    setNumPages(num) {
      this.pageCount = num;
    },
    setCurPage(num) {
      this.currentPage = num;
    },
    setEstimateType(val) {
      this.estimateType = val;
      this.resetTimer(+val, "id_offer_price_type");
    },
    getKPInfo() {
      query(
        "post",
        "getOrderKPinfo",
        {
          uuid: this.$route.params.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      )
        .then((result) => {
          this.kpData = result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.changeOrderKPinfo();
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(value, label) {
      this.sendData[label] = value;
      this.stopTimer();
      this.startTimer();
    },
    changeOrderKPinfo() {
      this.showPdf = false;
      const data = {
        uuid: this.$route.params.id,
      };
      query(
        "post",
        "changeOrderKPinfo",
        { ...this.sendData, ...data },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.showPdf = true;
        this.getPdf();
      });
    },
    onError(err) {
      console.log(err);
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    token() {
      return localStorage.getItem("token");
    },
  },
  mounted() {},
  components: {
    Modal,
    Button,
    Pdf,
    Input,
    Textarea,
    CalendarInput,
  },
};
</script>
